import { createContext, type PropsWithChildren, useRef, useState } from 'react';

import noop from 'lodash/noop';

interface Options {
  type: 'alert' | 'confirm';
  title: string;
  message?: string | JSX.Element;
  field?: boolean;
  defaultFieldText?: string;
  primaryBtnText: string;
  secondaryBtnText?: string;
}

// Create Context Object
export const PromptContext = createContext<{
  open: boolean;
  options: Options;
  approve: (text?: string) => void;
  deny: () => void;
}>({
  open: true,
  options: {
    type: 'alert',
    title: '',
    primaryBtnText: 'Ok',
  },
  approve: noop,
  deny: noop,
});

// Create a provider for components to consume and subscribe to changes
export const PromptContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [options, setOptions] = useState<Options>({
    type: 'alert',
    title: '',
    primaryBtnText: 'Ok',
  });
  const [open, setOpen] = useState(false);
  const resolveRef = useRef<(val: boolean | string) => void>();
  if (typeof window !== 'undefined') {
    window.alertDH = (title: string, options?: Partial<Omit<Options, 'secondaryBtnText' | 'type' | 'title'>>) => {
      setOptions({ title, type: 'alert', primaryBtnText: 'Ok', ...options });
      setOpen(true);
      return new Promise((resolve) => (resolveRef.current = resolve));
    };

    window.confirmDH = (title: string, options?: Partial<Omit<Options, 'type' | 'title'>>) => {
      setOptions({ title, type: 'confirm', primaryBtnText: 'Ok', secondaryBtnText: 'Cancel', ...options });
      setOpen(true);
      return new Promise((resolve) => (resolveRef.current = resolve));
    };
  }

  return (
    <PromptContext.Provider
      value={{
        open,
        options,
        approve: (text?: string) => {
          setOpen(false);
          resolveRef.current?.(text || true);
        },
        deny: () => {
          setOpen(false);
          resolveRef.current?.(false);
        },
      }}
    >
      {children}
    </PromptContext.Provider>
  );
};
declare global {
  interface Window {
    confirmDH: (message: string, options?: Partial<Omit<Options, 'type' | 'title'>>) => Promise<boolean | string>;
    alertDH: (
      message: string,
      options?: Partial<Omit<Options, 'secondaryBtnText' | 'type' | 'title'>>,
    ) => Promise<boolean | string>;
  }
}
